<template>
    <b-container fluid>
        <header-default></header-default>
        <div class="identity">
            <b-container>
                <router-view></router-view>
            </b-container>
        </div>
        <footer-default></footer-default>
    </b-container>
</template>
<script>
import headerDefault from './components/header_default';
import footerDefault from './components/footer_default';
export default {
    name: 'accountLayout',
    components:{headerDefault,footerDefault},
}
</script>
<style lang="scss" scoped>
.identity{
    min-height: calc(100vh - 168px);
    background: url('http://cdnsponsor.xiaoguokeji.com/login_bg02.jpg') center no-repeat;
    padding: 50px 0;
    margin-top: 60px;
    .container{
        min-height: auto;
    }
}
</style>


