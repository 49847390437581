<template>
    <b-container fluid>
        <div class="wrap">
            <router-view />
        </div>
        <footer-default></footer-default>
    </b-container>
</template>

<script>
import footerDefault from './components/footer_default';
export default {
    name: 'campusAmbassador',
    components:{footerDefault},
}
</script>
