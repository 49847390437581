import Vue from 'vue'
import * as filters from '@/filters'//过滤器
import API from '@/assets/utils/API'
import server from '@/assets/utils/request'
import BootstrapVue from 'bootstrap-vue'
import ElementUI from 'element-ui'
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI)

Vue.prototype.API = API; //全局接口
Vue.prototype.server = server; //全局axios请求封装

import Message from '@/components/message';
Vue.prototype.$message = Message; //自己封装的message组件 用法同Element.Message

import Vuelidate from 'vuelidate'//表单验证
Vue.use(Vuelidate)


Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
})

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
