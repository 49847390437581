<template>
  <header class="container-fluid header_default">
    <b-navbar
      toggleable="lg"
      class="header_wrap"
    >
      <b-navbar-brand href="/">
        <img
          :src="logo"
          height="30"
          alt=""
        >
      </b-navbar-brand>
      <b-navbar-nav style="flex-direction: row;margin-left:2rem">
        <b-nav-item
          v-for="(item,index) in outRoutes"
          :key="index"
          :href='item.path'
          :class="[item.path=='/'?'d-none d-lg-inline-block ':'',item.path == currentRoute || currentRoute.slice(-1)==item.type?'currentRoute':'']"
          rel="nofollow"
          target="_blank"
        >
          {{item.name}}
        </b-nav-item>

      </b-navbar-nav>
      <b-navbar-toggle target="nav_collapse" />

      <b-collapse
        is-nav
        id="nav_collapse"
      >
        <b-navbar-nav>
          <b-nav-item
            v-for="(item,index) in type==2||admin==1?routes2:routes"
            :key="index"
            :href='item.path'
            :class="[item.both?' d-lg-none':'',item.path==currentRoute?'currentRoute':'']"
            rel="nofollow"
            target="_blank"
          >
            {{item.name}}
          </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="d-none d-lg-inline-block">
          <b-button
            class="btn release_btn"
            @click="release"
          >
            <i class="icon iconfont iconjiahao"></i> 发布赞助
          </b-button>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav
          v-if='alreadyLogin'
          class="ml-auto"
        >
          <div v-if="$store.state.user.type === 1">
            <!-- 认证信息 -->
            <b-button
              v-if="is_real === 0 || is_real  === 3"
              variant="outline-primary"
              pill
              style="padding:0 10px;font-size:12px;line-height:25px;height:25px;margin-top:5px;"
              size="sm"
              @click="identify"
            >未认证</b-button>
            <b-button
              v-if="is_real === 1 "
              variant="outline-warning"
              pill
              style="padding:0 10px;font-size:12px;line-height:25px;height:25px;margin-top:5px;"
              size="sm"
              @click="identify"
            >审核中</b-button>
            <b-button
              v-if="is_real === 4"
              variant="outline-success"
              pill
              style="padding:0 10px;font-size:12px;line-height:25px;height:25px;margin-top:5px;"
              size="sm"
              @click="identify"
            >待验证</b-button>
            <b-button
              v-if="is_real === 2"
              variant="outline-success"
              pill
              style="padding:0 10px;font-size:12px;line-height:25px;height:25px;margin-top:5px;"
              size="sm"
              @click="identify"
            >已认证</b-button>
          </div>
          <b-dropdown variant="link">
            <template slot="button-content">
              <div
                class="userName"
                :title="name"
              >{{name}}</div>
              <!-- <i :class="['icon','iconfont',type==1?'iconqiye-copy':'iconxueshengcopy']"></i> -->
            </template>
            <!-- <b-dropdown-item class="d-none d-lg-block">
              <router-link :to='`/account/infoList`'>活动信息</router-link>
            </b-dropdown-item> -->
            <!-- <b-dropdown-item class="d-lg-none">
              <router-link :to='`/account/infoList`'>活动信息</router-link>
            </b-dropdown-item> -->
            <b-dropdown-item>
              <router-link :to='`/account/infoList`'>个人中心</router-link>
            </b-dropdown-item>
            <b-dropdown-item v-if="admin==1">
              <router-link :to='`/account/banner`'>Banner图管理</router-link>
            </b-dropdown-item>
            <b-dropdown-item @click="edit">退出登录</b-dropdown-item>
          </b-dropdown>
        </b-navbar-nav>
        <b-navbar-nav
          v-else
          class="ml-auto"
          style="flex-direction: row"
        >
          <a :href="`/login/login${isMobile?'?type=2':''}`">登录</a>
          <span class="d-none d-md-inline-block"> &nbsp;|&nbsp; </span>
          <a :href="`/login/login${isMobile?'?type=2':''}`">注册</a>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <!-- 认证弹窗 -->
    <b-modal
      class="modal"
      id="bv-modal-identify"
      ref="identify"
      hide-footer
    >
      <template #modal-title>
        {{ is_real !== 4?'企业认证': '公户打款核验'}}
      </template>
      <div
        v-if="is_real === 0 || is_real  === 3"
        class="d-block modal-body_wrap"
      >
        <h6
          class="modal-title"
          style="margin-bottom: 24px;"
        >为确保赞助企业真实性，现对企业进行公户打款验证，请在下方填写您的公开信息。</h6>
        <b-form
          @submit.prevent="onSubmit"
          autocomplete="off"
        >
          <b-form-group
            label-cols="3"
            label="开户名称："
            label-for="account_name"
          >
            <b-form-input
              required
              v-model="formData.account_name"
              placeholder="请输入开户名称"
              id="account_name"
            ></b-form-input>
          </b-form-group>
          <p style="font-size:12px;margin:-10px 0 10px 20.5%;color:#DC3432;">开户名称必须跟企业名称保持一致</p>

          <b-form-group
            label-cols="3"
            label="开户银行："
            label-for="bank_name"
          >
            <b-form-input
              required
              v-model="formData.bank_name"
              placeholder="请输入开户银行"
              id="bank_name"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label-cols="3"
            label="银行账号："
            label-for="bank_account"
          >
            <b-form-input
              required
              v-model="formData.bank_card"
              placeholder="请输入银行账号"
              id="bank_account"
            ></b-form-input>
          </b-form-group>
          <p style="font-size:14px;font-family: PingFangSC-Regular, PingFang SC;line-height: 20px;color: #999999;margin:-10px 0 17px 20.5%;">我们会给该账户汇入一笔非常小的金额，后续审核人员会致电您可确认。</p>
          <div class="btn-wrap">
            <b-button
              type="submit"
              style="height:45px;background: #f73636;border-width:0;width:484px;display:inline-block;"
              block
            >提交审核</b-button>
          </div>

        </b-form>
      </div>
      <div
        v-if="is_real === 1"
        class="modal-body_wrap"
      >
        <h6
          class="modal-title"
          style=" margin-top: 12px;;margin-bottom: 141px;text-align:center;"
        >您的企业信息正在审核中，请耐心等待！</h6>
        <div
          class="btn-wrap"
          style="text-align:center;"
        >
          <b-button
            style="height:45px;background: #f73636;border-width:0;width:476px;display:inline-block;margin-bottom:71px;"
            block
            @click="cancelModal"
          >知道了</b-button>
        </div>
      </div>
      <div
        v-if="is_real === 4"
        class="modal-body_wrap"
      >
        <h6
          class="modal-title"
          style="margin-bottom: 24px;margin-top: 12px;"
        >平台已向贵公司汇入一笔小额钱款，并在汇款单上附带了6位数验证码，请在下方输入该验证码。</h6>
        <b-form
          @submit.prevent="onSubmitCode"
          autocomplete="off"
          style="width:492px;margin: 0 auto;"
        >
          <b-form-group
            label-cols="2"
            label="验证码："
            label-for="code"
          >
            <b-form-input
              required
              v-model="codeData.code"
              placeholder="请输入验证码"
              id="code"
            ></b-form-input>
          </b-form-group>
          <p
            v-if="codeErr"
            style="text-align:center;font-size: 14px;color: rgb(247, 54, 54);"
          >验证码错误</p>
          <b-button
            style="height:45px;background: #f73636;border-width:0;margin:47px 0 72px 0;"
            block
            type="submit"
          >提交验证</b-button>
        </b-form>
      </div>
      <div
        v-if="is_real === 2"
        class="modal-body_wrap"
      >
        <h6 style="margin-bottom: 92px;height:50px;display:flex;align-items:center;justify-content:center;text-align:center;font-size:28px;"><i
            style="margin-right:20px;font-size:50px;color:#2D77FF;"
            class="iconfont iconduihao1"
          ></i><span style="line-height:50px;">恭喜你，已完成企业认证！</span></h6>
        <div
          class="btn-wrap"
          style="text-align:center;"
        >
          <b-button
            class="mt-3"
            style="height:45px;background: #f73636;border-width:0;width:476px;display:inline-block;margin-bottom:71px;"
            block
            @click="$bvModal.hide('bv-modal-identify')"
          >知道了</b-button>
        </div>
      </div>
    </b-modal>
  </header>
</template>
<script>
import { mapGetters } from 'vuex'
import { getToken } from '@/assets/js/fun'
import Message from '@/components/message';
export default {
  name: 'headerDefault',
  computed: {
    ...mapGetters([
      'name',
      'type',
      'admin',
      'is_real'
    ]),

    currentRoute () {
      return this.$route.path
    },
  },
  data () {
    return {
      alreadyLogin: false,
      logo: 'http://cdnsponsor.xiaoguokeji.com/logo.png',
      outRoutes: [
        { name: '首页', path: '/' },
        { name: '解决方案', path: '/prescription/prescription' },
        { name: '企业赞助', path: '/sponsor/businessList', type: '1' },
        // { name: '校园活动', path: '/sponsor/studentList', type: '2' },
        // { name: '校园合伙人', path: '/associate/associate' },
      ],
      routes: [
        { name: '首页', path: '/', both: true },
          { name: '解决方案', path: '/prescription/prescription', both: true },
        { name: '企业赞助', path: '/sponsor/businessList', type: '1', both: true },
        { name: '校园活动', path: '/sponsor/studentList', type: '2', },
          { name: '校园合伙人', path: '/associate/associate', },
        // { name: '校园推广', path: 'https://www.xiaoguokeji.com/' },
        // { name: '校园红人', path: 'http://xkol.xiaoguokeji.com/' },
        // { name: '校园大使', path: '/campus/ambassador' }
      ],
      routes2: [
        { name: '首页', path: '/', both: true },
          { name: '解决方案', path: '/prescription/prescription', both: true },
        { name: '企业赞助', path: '/sponsor/businessList', type: '1', both: true },
        { name: '校园活动', path: '/sponsor/studentList', type: '2' },
          { name: '校园合伙人', path: '/associate/associate'},
        // { name: '校园推广', path: 'https://www.xiaoguokeji.com/' },
        // { name: '校园红人', path: 'http://xkol.xiaoguokeji.com/' },
        // {name:'投诉板块',path:'/complain/complainList'},
        // { name: '论坛板块', path: '/chat/chatList', type: '3' },
        // { name: '校园大使', path: '/campus/ambassador' }
      ],
      isMobile: null,
      // 表单数据
      formData: {
        account_name: '',
        bank_name: '',
        bank_card: ''
      },
      // 验证码表单
      codeData: {
        code: ''
      },
      codeErr: false
    }
  },
  methods: {
    /**
     * 发布赞助
     */
    release () {
      if (this.type) {
        this.$router.push('/sponsor/release');
      } else {
        if (this.alreadyLogin) {
          this.$router.push('/account/identity');
        } else {
          this.$router.push('/login/login');
        }
      }
    },
    /**
     * 退出登陆
     */
    edit () {
      this.$store.dispatch('RemoveToken');
      location.href = '/';
    },
    // 认证
    identify () {
      // this.$bvModal.show('bv-modal-identify')
      this.$refs.identify.show()
    },
    // 提交认证
    onSubmit () {
      this.server.send('PATCH', this.API.$USER + `?operate=review`, { ...this.formData, type: 1 }).then(res => {
        this.$store.dispatch('GetUserInfo');//更新用户数据
        this.clearForm()
        // this.$bvModal.hide('bv-modal-identify')
        this.cancelModal()
      }).catch(err => {
        this.$message.error(err.response.data.message)
      })
    },
    // 提交验证
    onSubmitCode () {
      this.server.send('POST', this.API.$REMITCHECK, { code: this.codeData.code }).then(res => {
        this.$store.dispatch('GetUserInfo');//更新用户数据
        this.clearCodeForm()
        this.codeErr = false
        // this.$bvModal.hide('bv-modal-identify')
      }).catch(err => {
        this.codeErr = true
      })
    },
    // 清空表单
    clearForm () {
      this.formData.account_name = ''
      this.formData.bank_name = ''
      this.formData.bank_card = ''
    },
    // 清空验证码表单
    clearCodeForm () {
      this.codeData.code = ''
    },
    cancelModal () {
      this.$refs.identify.hide()
    }
  },
  async mounted () {
    // this.isLogin && this.$store.dispatch('GetUserInfo');//获取用户信息
    this.isMobile = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
    if (getToken()) {
      this.alreadyLogin = true
      await this.$store.dispatch('GetUserInfo')
      if (this.$store.getters.is_real === 4) {
        this.$refs.identify.show()
      }
    }
  },
  watch: {
    '$store.getters.name' () {
      this.alreadyLogin = true
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/vars.scss";
.header_default {
  height: 60px !important;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(44, 44, 44, 0.06);
  position: fixed;
  top: 0;
  z-index: 99;
  .header_wrap {
    max-width: $wrap-width;
    margin: 0 auto;
    background: #fff;
    padding: 0.5rem;
    @media (max-width: 768px) {
      box-shadow: 0px 2px 6px 0px rgba(44, 44, 44, 0.06);
    }
    .release_btn {
      @include ThemeBtn(170px, 37px, #05aaff);
      box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.15);
      margin-left: 44px;
    }
    .navbar-nav {
      padding-top: 4px;
      .nav-item {
        margin-right: 1rem;
        &:nth-last-child(1) a{
          // margin-right: 0;
          // font-size: 15px;
        }
        &:nth-last-child(1){
          margin-right: 0.8rem;
        }
        &:nth-child(1) {
          // margin-left: 110px;
        }
        a {
          color: $baseFontColor;
        }
      }
      .currentRoute a {
        color: $theme-color;
      }
    }
    .navbar-toggler {
      padding: 0.25rem;
    }
  }
  a {
    color: $baseFontColor;
    font-size: 16px;
  }
}
.userName {
  width: 80px;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.modal {
  .modal-title {
    text-align: left;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 22px;
  }
  .modal-body_wrap {
    width: 592px;
    margin: 0 auto;
    .btn-wrap {
      text-align: right;
    }
  }
}
::v-deep  .modal-dialog {
  max-width: 698px;
  margin: calc(50vh - 191px) auto;
}
::v-deep  .col-3 {
  flex: 0 0 18%;
  max-width: 18%;
}
::v-deep  .modal-body {
  padding: 22px 0 27px 0;
}
::v-deep  .modal-header {
  padding: 14px 18px;
  border-bottom: 1px solid #999999;
  h5 {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
  }
  .close {
    height: 20px;
    padding: 14px 18px;
  }
}
</style>


