const getters = {
    admin:      state => state.user.admin,
    name:       state => state.user.name,
    type:       state => state.user.type,
    phone:      state => state.user.phone,
    wechat:     state => state.user.wechat,
    info:       state => state.user.info,
    deposit:    state => state.user.deposit,
    gender:     state => state.user.gender,
    published:  state => state.user.published,
    token:      state => state.user.access_token,
    regions:    state => state.city.regions,
    province:   state => state.city.province,
    medias:     state => state.media.medias,
    aims:       state => state.media.aims,
    school:     state => state.school.school,
    is_real:    state => state.user.is_real,
};

export default getters