<template>
    <b-container fluid>
        <div class="activity">
            <router-view />
        </div>
        
    </b-container>
</template>

<script>

export default {
    name: 'Layout',
    components:{},
    head(){
        return{
            title:'登录'
        }
    },
}
</script>

<style lang="scss" scoped>
.activity{
    height: 100vh;
    font-family:PingFangSC-Regular,PingFang SC;
    line-height: unset;

}
</style>