/**
 * 首页赞助合作伙伴
 */
export const parterArr = [
  {
    label: '19楼',
    Xposition: '0px',
    link: 'https://www.xiaoguokeji.com/news/547',
  },
  {
    label: '京东',
    Xposition: '0px',
    link: 'https://www.xiaoguokeji.com/news/292',
  },
  {
    label: '淘宝网',
    Xposition: '0px',
    link: 'https://www.xiaoguokeji.com/news/554',
  },
  {
    label: '高德地图',
    Xposition: '0px',
    link: 'https://www.xiaoguokeji.com/news/489',
  },
  {
    label: 'MOLIK',
    Xposition: '0px',
    link: 'https://www.xiaoguokeji.com/news/477',
  },
  {
    label: '噢啦',
    Xposition: '0px',
    link: 'https://www.xiaoguokeji.com/news/551',
  },
  {
    label: '百世快递',
    Xposition: '-184px',
    link: 'https://www.xiaoguokeji.com/news/702',
  },
  {
    label: '天猫超市',
    Xposition: '-184px',
    link: 'https://www.xiaoguokeji.com/news/380',
  },
  {
    label: '华图教育',
    Xposition: '-184px',
    link: 'https://www.xiaoguokeji.com/news/599',
  },
  {
    label: '屈臣氏',
    Xposition: '-184px',
    link: 'https://www.xiaoguokeji.com/news/629',
  },
  {
    label: '我爱我家',
    Xposition: '-184px',
    link: 'https://www.xiaoguokeji.com/news/703',
  },
  {
    label: '图怪兽',
    Xposition: '-184px',
    link: 'https://www.xiaoguokeji.com/news/626',
  },
  {
    label: '可口可乐',
    Xposition: '-368px',
    link: 'https://www.xiaoguokeji.com/news/491',
  },
  {
    label: '珂润',
    Xposition: '-368px',
    link: 'https://www.xiaoguokeji.com/news/630',
  },
  {
    label: '红豆',
    Xposition: '-368px',
    link: 'https://www.xiaoguokeji.com/news/659',
  },
  {
    label: '富途牛牛',
    Xposition: '-368px',
    link: 'https://www.xiaoguokeji.com/news/712',
  },
  {
    label: '香飘飘',
    Xposition: '-368px',
    link: 'https://www.xiaoguokeji.com/news/606',
  },
  {
    label: '金利来',
    Xposition: '-368px',
    link: 'https://www.xiaoguokeji.com/news/701',
  },
];
/**
 * 开学年份
 */
export function beginSchoolDate() {
  const schoolDate = [{ value: null, text: '请选择入学年份', disabled: true }];
  let nowYear = new Date().getFullYear();
  schoolDate.push({ value: nowYear + 1, text: `${nowYear + 1}` });
  for (let i = 0; i < 10; i++) {
    let temp = {
      value: nowYear - i,
      text: `${nowYear - i}`,
    };
    schoolDate.push(temp);
  }
  return schoolDate;
}
/**
 * 社团类型
 */
export const leagueType = [
  { value: null, text: '请选择社团类型', disabled: true },
  { value: 1, text: '外联部' },
  { value: 2, text: '学生会' },
  { value: 3, text: '文艺社' },
  { value: 4, text: '科技创新' },
  { value: 5, text: '专业学术' },
  { value: 6, text: '体育竞技' },
  { value: 7, text: '社会公益' },
  { value: 8, text: '职业实践' },
];
/**
 * 社团级别
 */
export const leagueLevel = [
  { value: null, text: '请选择社团级别', disabled: true },
  { value: 1, text: '校级' },
  { value: 2, text: '院级' },
];
/**
 * 社团职位
 */
export const leagueJob = [
  { value: null, text: '请选择社团职位', disabled: true },
  { value: 1, text: '主席' },
  { value: 2, text: '副主席' },
  { value: 3, text: '会长' },
  { value: 4, text: '副会长' },
  { value: 5, text: '部长' },
  { value: 6, text: '副部长' },
  { value: 7, text: '干事' },
];
/**
 * 社团规模
 */
export const leagueScale = [
  { value: null, text: '请选择社团规模', disabled: true },
  { value: 1, text: '少于50人' },
  { value: 2, text: '50-100人' },
  { value: 3, text: '100人以上' },
];
/**
 * 企业性质
 */
export const companyNature = [
  { value: null, text: '请选择企业性质', disabled: true },
  { value: 1, text: '国企' },
  { value: 2, text: '私营企业' },
  { value: 3, text: '股份制企业' },
  { value: 4, text: '上市公司' },
  { value: 5, text: '国家机关' },
  { value: 6, text: '事业单位' },
  { value: 7, text: '其他' },
];
/**
 * 企业规模
 */
export const companyScale = [
  { value: null, text: '请选择企业规模', disabled: true },
  { value: 1, text: '少于50人' },
  { value: 2, text: '50-200人' },
  { value: 3, text: '200人以上' },
];
/**
 * 推广类型
 */
export const promoteType = [
  // {value:null,text:'请选择推广类型',disabled:true},
  // {value:12,text:'线上投票'},
  { value: 1, text: '才艺活动' },
  { value: 3, text: '竞技赛事' },
  { value: 13, text: '公益活动' },
  { value: 8, text: '晚会典礼' },
  { value: 7, text: '演讲辩论' },
  { value: 9, text: '高校推广' },
  // { value: 6, text: '迎新招新' },
  { value: 4, text: '设计开发' },
  { value: 5, text: '讲座论坛' },
  { value: 2, text: '社会实践' },
  // { value: 10, text: '影视音乐' },
  { value: 11, text: '其他' },
];
/**
 * 活动规模
 */
export const activitySize = [
  { value: null, text: '请选择活动规模', disabled: true },
  { value: 1, text: '0-50人' },
  { value: 2, text: '50-100人' },
  { value: 3, text: '100-200人' },
  { value: 4, text: '200-400人' },
  { value: 5, text: '400-600人' },
  { value: 6, text: '600-1000人' },
  { value: 7, text: '1000人以上' },
];

/**
 * 请求错误信息
 */
export const error_code = [
  {
    code: 401,
    label: '账号信息已过期，请重新登录！',
  },
  {
    code: 403,
    label: '您的账号因被学生社团多次举报，现已限制登录。',
  },
];
