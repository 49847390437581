import { getRegion,getProvince } from '@/assets/js/fun'
const city = {
    state: {
        regions:[],
        province:[]
    },
    mutations:{
        SET_REGIONS:(state,regions)=>{
            state.regions = regions;
        },
        SET_PROVINCE:(state,province)=>{
            state.province = province;
        },
    },
    actions:{
        //获取城市三级联动
        GetRegions({ commit }){
            getRegion().then(res=>{
                commit('SET_REGIONS',res.data);
            }).catch(error => {
                console.log(error);
            })
        },
        //获取x相应省份
        GetProvince({ commit },type){
            getProvince(type).then(res=>{
                commit('SET_PROVINCE',res.data);
            }).catch(error => {
                console.log(error);
            })
        }
    }
};

export default city;