import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _3287e43e = () => interopDefault(import('../pages/account/banner.vue' /* webpackChunkName: "pages/account/banner" */))
const _27817c8c = () => interopDefault(import('../pages/account/chooseIndetity.vue' /* webpackChunkName: "pages/account/chooseIndetity" */))
const _377bd333 = () => interopDefault(import('../pages/account/identity.vue' /* webpackChunkName: "pages/account/identity" */))
const _32cd45a3 = () => interopDefault(import('../pages/account/info.vue' /* webpackChunkName: "pages/account/info" */))
const _1fd53707 = () => interopDefault(import('../pages/account/infoForm.vue' /* webpackChunkName: "pages/account/infoForm" */))
const _53ce5de1 = () => interopDefault(import('../pages/account/infoList.vue' /* webpackChunkName: "pages/account/infoList" */))
const _67a69902 = () => interopDefault(import('../pages/activity/information.vue' /* webpackChunkName: "pages/activity/information" */))
const _dbc35822 = () => interopDefault(import('../pages/activity/recruitment.vue' /* webpackChunkName: "pages/activity/recruitment" */))
const _c5852704 = () => interopDefault(import('../pages/activity/votemini.vue' /* webpackChunkName: "pages/activity/votemini" */))
const _0591152c = () => interopDefault(import('../pages/associate/associate.vue' /* webpackChunkName: "pages/associate/associate" */))
const _098e5b3e = () => interopDefault(import('../pages/campus/ambassador.vue' /* webpackChunkName: "pages/campus/ambassador" */))
const _7b07d220 = () => interopDefault(import('../pages/campus/ambassador-detail.vue' /* webpackChunkName: "pages/campus/ambassador-detail" */))
const _51190caa = () => interopDefault(import('../pages/chat/chatList.vue' /* webpackChunkName: "pages/chat/chatList" */))
const _6ab7a74a = () => interopDefault(import('../pages/complain/complainList.vue' /* webpackChunkName: "pages/complain/complainList" */))
const _e5cfd956 = () => interopDefault(import('../pages/login/accountlogin.vue' /* webpackChunkName: "pages/login/accountlogin" */))
const _7075793e = () => interopDefault(import('../pages/login/forgetPassword.vue' /* webpackChunkName: "pages/login/forgetPassword" */))
const _03022980 = () => interopDefault(import('../pages/login/login.vue' /* webpackChunkName: "pages/login/login" */))
const _9bf934c8 = () => interopDefault(import('../pages/login/register.vue' /* webpackChunkName: "pages/login/register" */))
const _c372109a = () => interopDefault(import('../pages/login/scanbind.vue' /* webpackChunkName: "pages/login/scanbind" */))
const _31483b2c = () => interopDefault(import('../pages/prescription/prescription.vue' /* webpackChunkName: "pages/prescription/prescription" */))
const _212f4d5a = () => interopDefault(import('../pages/registerprocess/bind.vue' /* webpackChunkName: "pages/registerprocess/bind" */))
const _f000392a = () => interopDefault(import('../pages/registerprocess/chooseidentity.vue' /* webpackChunkName: "pages/registerprocess/chooseidentity" */))
const _0826c75d = () => interopDefault(import('../pages/registerprocess/success.vue' /* webpackChunkName: "pages/registerprocess/success" */))
const _431d41b4 = () => interopDefault(import('../pages/sponsor/businessList.vue' /* webpackChunkName: "pages/sponsor/businessList" */))
const _1aae3e0f = () => interopDefault(import('../pages/sponsor/release.vue' /* webpackChunkName: "pages/sponsor/release" */))
const _274f2681 = () => interopDefault(import('../pages/sponsor/studentList.vue' /* webpackChunkName: "pages/sponsor/studentList" */))
const _5b00f17e = () => interopDefault(import('../pages/campus/components/recruit-item.vue' /* webpackChunkName: "pages/campus/components/recruit-item" */))
const _d90ab356 = () => interopDefault(import('../pages/account/setInfo/_type.vue' /* webpackChunkName: "pages/account/setInfo/_type" */))
const _14c77cf3 = () => interopDefault(import('../pages/registerprocess/_type.vue' /* webpackChunkName: "pages/registerprocess/_type" */))
const _906b9ac0 = () => interopDefault(import('../pages/chat/_id/_type.vue' /* webpackChunkName: "pages/chat/_id/_type" */))
const _3565df0c = () => interopDefault(import('../pages/sponsor/_id/_type.vue' /* webpackChunkName: "pages/sponsor/_id/_type" */))
const _bf3259e2 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

const scrollBehavior = function(to, from, savedPosition) {
      return { x: 0, y: 0 };
    }

export function createRouter() {
  return new Router({
    mode: 'history',
    base: decodeURI('/'),
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [{
      path: "/account/banner",
      component: _3287e43e,
      name: "account-banner"
    }, {
      path: "/account/chooseIndetity",
      component: _27817c8c,
      name: "account-chooseIndetity"
    }, {
      path: "/account/identity",
      component: _377bd333,
      name: "account-identity"
    }, {
      path: "/account/info",
      component: _32cd45a3,
      name: "account-info"
    }, {
      path: "/account/infoForm",
      component: _1fd53707,
      name: "account-infoForm"
    }, {
      path: "/account/infoList",
      component: _53ce5de1,
      name: "account-infoList"
    }, {
      path: "/activity/information",
      component: _67a69902,
      name: "activity-information"
    }, {
      path: "/activity/recruitment",
      component: _dbc35822,
      name: "activity-recruitment"
    }, {
      path: "/activity/votemini",
      component: _c5852704,
      name: "activity-votemini"
    }, {
      path: "/associate/associate",
      component: _0591152c,
      name: "associate-associate"
    }, {
      path: "/campus/ambassador",
      component: _098e5b3e,
      name: "campus-ambassador"
    }, {
      path: "/campus/ambassador-detail",
      component: _7b07d220,
      name: "campus-ambassador-detail"
    }, {
      path: "/chat/chatList",
      component: _51190caa,
      name: "chat-chatList"
    }, {
      path: "/complain/complainList",
      component: _6ab7a74a,
      name: "complain-complainList"
    }, {
      path: "/login/accountlogin",
      component: _e5cfd956,
      name: "login-accountlogin"
    }, {
      path: "/login/forgetPassword",
      component: _7075793e,
      name: "login-forgetPassword"
    }, {
      path: "/login/login",
      component: _03022980,
      name: "login-login"
    }, {
      path: "/login/register",
      component: _9bf934c8,
      name: "login-register"
    }, {
      path: "/login/scanbind",
      component: _c372109a,
      name: "login-scanbind"
    }, {
      path: "/prescription/prescription",
      component: _31483b2c,
      name: "prescription-prescription"
    }, {
      path: "/registerprocess/bind",
      component: _212f4d5a,
      name: "registerprocess-bind"
    }, {
      path: "/registerprocess/chooseidentity",
      component: _f000392a,
      name: "registerprocess-chooseidentity"
    }, {
      path: "/registerprocess/success",
      component: _0826c75d,
      name: "registerprocess-success"
    }, {
      path: "/sponsor/businessList",
      component: _431d41b4,
      name: "sponsor-businessList"
    }, {
      path: "/sponsor/release",
      component: _1aae3e0f,
      name: "sponsor-release"
    }, {
      path: "/sponsor/studentList",
      component: _274f2681,
      name: "sponsor-studentList"
    }, {
      path: "/campus/components/recruit-item",
      component: _5b00f17e,
      name: "campus-components-recruit-item"
    }, {
      path: "/account/setInfo/:type?",
      component: _d90ab356,
      name: "account-setInfo-type"
    }, {
      path: "/registerprocess/:type?",
      component: _14c77cf3,
      name: "registerprocess-type"
    }, {
      path: "/chat/:id?/:type?",
      component: _906b9ac0,
      name: "chat-id-type"
    }, {
      path: "/sponsor/:id?/:type?",
      component: _3565df0c,
      name: "sponsor-id-type"
    }, {
      path: "/",
      component: _bf3259e2,
      name: "index"
    }],

    fallback: false
  })
}
