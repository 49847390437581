import { getSchool } from '@/assets/js/fun'
const school = {
    state: {
        school:[],
    },
    mutations:{
        SET_SCHOOL:(state,school)=>{
            state.school = school;
        }
    },
    actions:{
        //获取学校
        GetSchool({ commit }){
            getSchool().then(res=>{
                commit('SET_SCHOOL',res.data);
            }).catch(error => {
                console.log(error);
            })
        }
    }
};

export default school;