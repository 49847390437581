import axios from 'axios'
import { getToken,removeToken} from '@/assets/js/fun';
import Message from '@/components/message';
import {getError} from '../js/fun'

// create an axios instance
const send = function(method,url,data,params){
    "use strict"
    // var baseUrl = process.env.NODE_ENV === 'production' ? 'http://www.51zanzhu.net' : 'http://101.200.205.243:8091'
    var baseUrl = 'http://www.51zanzhu.net'
    return axios({
        method :method,
        url    : `${baseUrl}/api${url}`,
        headers:{
            'Content-Type':'application/json'
        },
        timeout: 10000, // request timeout
        data   : data ,
        params : params
    }).then(function(res){
        return res;
    }).catch(function(err){
        const status = err && err.response && err.response.status || '';    
        Message({
            message:getError(status),
            type:'warning'
        });     
        if(status==401||status==403){
            removeToken();
            location.href = '/';
        }  
        return Promise.reject(err);
    });
};   
// request interceptor
axios.interceptors.request.use(function(config){
    // Do something before request is sent
    const token = getToken();

    if (token) {
        config.headers.Authorization = "Bearer " + token // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
    }
    return config
}, function(error){
    // Do something with request error
    return Promise.reject(error)
});
export default {
    send
}
