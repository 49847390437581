import {promoteType,activitySize,leagueType,leagueJob,companyNature,leagueScale} from '@/assets/js/arrayData';

/**
 * 推广类型
 */
export function PromoteType(typeCode) {
    let str = '';
    if(typeCode){
        if(typeCode==12){
            str = "线上投票";
        }else{
            promoteType.forEach(val=>{
                if(val.value == typeCode) str = val.text;
            })
        }
    }

    return str;
}
/**
 * 活动规模
 */
export function activityPeople(typeCode) {
    let str = '';
    if(typeCode){
        activitySize.forEach(val=>{
            if(val.value == typeCode) str = val.text;
        })
    }
    return str;
}
/**
 * 社团类型
 */
export function leagueKind(typeCode) {
    let str = '';
    if(typeCode){
        leagueType.forEach(val=>{
            if(val.value == typeCode) str = val.text;
        })
    }
    return str;
}
/**
 * 社团职位
 */
export function schoolJob(typeCode) {
    let str = '';
    if(typeCode){
        leagueJob.forEach(val=>{
            if(val.value == typeCode) str = val.text;
        })
    }
    return str;
}
/**
 * 公司类型
 */
export function companyType(typeCode) {
    let str = '';
    if(typeCode){
        companyNature.forEach(val=>{
            if(val.value == typeCode) str = val.text;
        })
    }
    return str;
}
/**
 * 企业规模
 */
export function companyScale(typeCode) {
    let str = '';
    if(typeCode){
        leagueScale.forEach(val=>{
            if(val.value == typeCode) str = val.text;
        })
    }
    return str;
}

/**
 * 上传的文件名
 */
export function fileName(name) {
    let str = '';
    let first = name.indexOf('_');
    str = name.substring(first+1)
    return str;
}

/**
 * 时间只取年月日
 */
export function subTime(time) {
    return time=time.substr(0,10)  
}
export function  recruitNumFilter (val) {
    switch (val) {
      case 1:
        return '1人'
      case 2:
        return '2人'
      case 3:
        return '3-5人'
      case 4:
        return '5-10人'
      case 5:
        return '10-20人'
      case 6:
        return '若干人'
      default:
        return
    }
  }
  export function  regionsFilter (val = []) {
    let arr = []
    val.forEach(item => {
      arr.push(item.name)
    })
    return arr.join('、')
  }
  export function  industryFilter (val) {
    switch (val) {
      case 1:
        return '教育行业'
      case 2:
        return '快消品行业'
      case 3:
        return '美妆行业'
      case 4:
        return '文娱行业'
      case 5:
        return '移动应用'
      case 6:
        return '地产家居'
      case 7:
        return '游戏电竞'
      case 8:
        return '互联网/电子商务'
      default:
        return
    }
  }
  export function scaleFilter (val) {
    switch (val) {
      case 1:
        return '0-50人'
      case 2:
        return '50-100人'
      case 3:
        return '100-500人'
      case 4:
        return '500-2000人'
      case 5:
        return '2000人以上'
      default:
        return
    }
  }
  export function fundingFilter (val) {
    switch (val) {
      case 1:
        return '未融资'
      case 2:
        return '天使轮'
      case 3:
        return 'A轮'
      case 4:
        return 'B轮'
      case 5:
        return 'C轮'
      case 6:
        return 'D轮及以上'
      case 7:
        return '已上市'
      case 8:
        return '不需要融资'
      default:
        return
    }
  }