const API = {
    $REGISTER_CODE: '/verification-code/register', //注册时发送验证码
    $RESET_PASSWORD_CODE: '/verification-code/reset',//重设密码发送验证码
    $REGISTERORLOGIN_CODE: '/verification-code/login-register',//登录或注册发送验证码
    $IMG_CODE: '/captchas',//图形验证码
    $REGISTER: '/auth/register',//注册
    $LOGIN: '/auth/login',//账号登录
    $LOGINORREGISTER: '/login-register',//验证码登录或注册
    $RESET_PASSWORD: '/user/reset-password',//重设密码
    $SPONSOR: '/sponsor',//赞助
    $ACIIVITY: '/events',//活动
    $USERINFO: '/me',//用户信息
    $HOME: '/home',//首页展示数据信息
    $SCHOOL: '/schools',//所有可选学校
    $REGIONS: '/regions',//省级市级三级联动
    $PROVINCE: '/provinces',//省级
    $PROVINCE_SPONSOR: '/provinces/sponsor',//有赞助的省
    $PROVINCE_EVENT: '/provinces/event',//有活动的省
    $USER: '/user',//用户信息设置
    $USERSPONSOR: '/user/sponsor',//用户赞助列表
    $USERACTIVITY: '/user/events',//用户活动列表
    $MEDIA: '/medias',//类别
    $AIMS: '/aims',//推广目的
    $QINIU: '/qiniu/token',//七牛云token
    $COMMENT: '/comments',//获取投诉列表
    $COMOLAIN: '/sponsor',//投诉sponsor/:id/comments
    $WECHATLOGIN: '/wx/login',//拿到微信code请求拿openid
    $WECHATECODELOGIN: '/verification-code/wechat', //在微信登录，不需要图形验证码
    $CHAT: '/posts',//论坛
    $SCAN: '/wx/qrcode',//获取微信二维码
    $SCANSTATUS: '/wx/qrcode/status',//二维码登录状态（轮询）
    $BINDPHONE: '/user/phone/bind',//扫码后绑定手机号
    $WELOGIN: '/wechat/login',
    $BANNER: '/banners',  //轮播图
    $RECRUITS: '/jobs',  // 招募信息
    $DELIVERY: '/send/resume', // 投递简历
    $RECRUITDEMAND: '/job/demand', // 提交招募需求
    $REMITCHECK: '/remit/check'  // 打款验证码校验

}
export default API;
