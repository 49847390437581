import Cookies from 'js-cookie'
import axios from 'axios'
import server from "@/assets/utils/request";
import API from '@/assets/utils/API';
import {error_code} from './arrayData'

const TokenKey = 'ASSESSTOKEN'
/**
 * 获取TOKEN
 */
export function getToken() {
    return Cookies.get(TokenKey)
}
/**
 * 设置TOKEN
 */
export function setToken(token) {
    let millisecond = new Date().getTime();
    let expiresTime = new Date(millisecond + 60 * 1000 * 60 * 24);//设置token过期时间为24小时
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;//设置请求头部

    return Cookies.set(TokenKey, token,{expires:expiresTime});
}
/**
 * 清除TOKEN
 */
export function removeToken() {
    return Cookies.remove(TokenKey)
}
/**
 * 记住账号
 */
export function rememberMe(phone) {
    return Cookies.set('accountPhone',phone)
}
/**
 * 获取账号
 */
export function getaccountphone() {
    return Cookies.get('accountPhone')
}
/**
 * 忘记账号
 */
export function forgetMe() {
    return Cookies.remove('accountPhone')
}
/**
 * 获取用户信息
 */
export function getUserInfo() {
    return server.send('get',API.$USERINFO);
}
/**
 * 获取城市三级
 */
export function getRegion() {
    return server.send('get',API.$REGIONS);
}
/**
 * 获取省份
 */
export function getProvince(type) {
    switch(type){
        case 'sponsor':
            return server.send('get',API.$PROVINCE_SPONSOR);
        case 'event':
            return server.send('get',API.$PROVINCE_EVENT);
    }
}
/**
 * 获取学校
 */
export function getSchool() {
    return server.send('get',API.$SCHOOL);
}
/**
 * 获取类别
 */
export function getMedias() {
    return server.send('get',API.$MEDIA);
}
/**
 * 获取推广目的
 */
export function getAims() {
    return server.send('get',API.$AIMS);
}


/**
 * 获取路由中参数
 */
export function getParams(){
    let params = {};
    if(window.location.search){
        let str = window.location.search.split('?')[1];
        const queryList = str.split('&')     
        str && queryList.map((item) => {        
            let keyValue = item.split('=')        
            params[keyValue[0]] = decodeURIComponent(keyValue[1])    
        })
    }
    return params;
}
//判断是否是微信域
export function isWechat(){
    let ua = navigator.userAgent.toLowerCase();
    let isWeixin = ua.indexOf('micromessenger') != -1;
　　return isWeixin
}
//微信授权
export function authorization(){
    let params = getParams();
    if(!params.code){
        let url = encodeURIComponent(window.location.href);
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx14481b6ea1c473f6&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
    }else{
        server.send('POST',API.$WELOGIN,{
            code:params.code
        }).then(res=>{
            return res.data.token
        })
    }
}

/**
 * 获取cookie里的手机号
 */
export function getPhone() {
    return Cookies.get('phone')
}
/**
 * 设置手机号到cookie
 */
export function setPhone(phone) {
    return Cookies.set('phone', phone);
}

//返回请求错误状态
export function getError(code){
    let str
    error_code.forEach(item=>{
        if(code==item.code){
            str = item.label
        }
    })
    return str
}
