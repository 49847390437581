import Vue from 'vue'
import NuxtLoading from './components/nuxt-loading.vue'

import '../node_modules/bootstrap/dist/css/bootstrap.css'

import '../node_modules/bootstrap-vue/dist/bootstrap-vue.css'

import '../assets/css/global.scss'

import _18ef6f81 from '../layouts/accountLayout.vue'
import _160badf6 from '../layouts/activity.vue'
import _ab6a1066 from '../layouts/activity2.vue'
import _639e9b06 from '../layouts/campusAmbassador.vue'
import _b0f66700 from '../layouts/components/footer_default.vue'
import _601fe772 from '../layouts/components/header_default.vue'
import _25980fd9 from '../layouts/components/sidebar.vue'
import _6f6c098b from '../layouts/default.vue'
import _89033b86 from '../layouts/loginLayout.vue'
import _25987a00 from '../layouts/personalLayout.vue'

const layouts = { "_accountLayout": _18ef6f81,"_activity": _160badf6,"_activity2": _ab6a1066,"_campusAmbassador": _639e9b06,"_components/footer_default": _b0f66700,"_components/header_default": _601fe772,"_components/sidebar": _25980fd9,"_default": _6f6c098b,"_loginLayout": _89033b86,"_personalLayout": _25987a00 }

export default {
  head: {"title":"51赞助 - 校园活动 - 校园赞助 全国校园活动赞助平台,高效寻找活动赞助的平台","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"http-equiv":"X-UA-Compatible","content":"IE=edge"},{"hid":"keywords","name":"keywords","content":"51赞助,活动,赞助,活动赞助,校园活动,社团赞助,校园推广,校园营销,找赞助,赞助商,拉赞助,活动推广,活动营销,校园活动赞助,校园活动营销"},{"hid":"description","name":"description","content":"51赞助致力于为品牌提供低风险、高性价比的一站式校园活动赞助解决方案，覆盖全国1000多所高校，2100多个社团等优质校园资源，一分钟快速注册，迅速匹配最优活动。"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"},{"rel":"stylesheet","href":"\u002F\u002Fat.alicdn.com\u002Ft\u002Ffont_1093982_gli3bljkkli.css"}],"script":[{"innerHTML":"\n                    var _hmt = _hmt || [];\n                    (function () {\n                    var hm = document.createElement(\"script\");\n                    hm.src = \"https:\u002F\u002Fhm.baidu.com\u002Fhm.js?2c2c06f8f1ba76103b9efd013e6d748f\";\n                    var s = document.getElementsByTagName(\"script\")[0]; \n                    s.parentNode.insertBefore(hm, s);\n                    })();\n                "},{"src":"https:\u002F\u002Fwww.googletagmanager.com\u002Fgtag\u002Fjs?id=UA-119788714-1","async":"async"},{"src":"\u002Fjs\u002Fga.js","async":"async"}],"__dangerouslyDisableSanitizers":["script"],"style":[]},

  render(h, props) {
    const loadingEl = h('NuxtLoading', { ref: 'loading' })
    const layoutEl = h(this.layout || 'nuxt')
    const templateEl = h('div', {
      domProps: {
        id: '__layout'
      },
      key: this.layoutName
    }, [ layoutEl ])

    const transitionEl = h('transition', {
      props: {
        name: 'layout',
        mode: 'out-in'
      },
      on: {
        beforeEnter(el) {
          // Ensure to trigger scroll event after calling scrollBehavior
          window.$nuxt.$nextTick(() => {
            window.$nuxt.$emit('triggerScroll')
          })
        }
      }
    }, [ templateEl ])

    return h('div', {
      domProps: {
        id: '__nuxt'
      }
    }, [
      loadingEl,
      transitionEl
    ])
  },
  data: () => ({
    isOnline: true,
    layout: null,
    layoutName: ''
  }),
  beforeCreate() {
    Vue.util.defineReactive(this, 'nuxt', this.$options.nuxt)
  },
  created() {
    // Add this.$nuxt in child instances
    Vue.prototype.$nuxt = this
    // add to window so we can listen when ready
    if (process.client) {
      window.$nuxt = this
      this.refreshOnlineStatus()
      // Setup the listeners
      window.addEventListener('online', this.refreshOnlineStatus)
      window.addEventListener('offline', this.refreshOnlineStatus)
    }
    // Add $nuxt.error()
    this.error = this.nuxt.error
  },

  mounted() {
    this.$loading = this.$refs.loading
  },
  watch: {
    'nuxt.err': 'errorChanged'
  },

  computed: {
    isOffline() {
      return !this.isOnline
    }
  },
  methods: {
    refreshOnlineStatus() {
      if (process.client) {
        if (typeof window.navigator.onLine === 'undefined') {
          // If the browser doesn't support connection status reports
          // assume that we are online because most apps' only react
          // when they now that the connection has been interrupted
          this.isOnline = true
        } else {
          this.isOnline = window.navigator.onLine
        }
      }
    },

    errorChanged() {
      if (this.nuxt.err && this.$loading) {
        if (this.$loading.fail) this.$loading.fail()
        if (this.$loading.finish) this.$loading.finish()
      }
    },

    setLayout(layout) {
      if (!layout || !layouts['_' + layout]) {
        layout = 'default'
      }
      this.layoutName = layout
      this.layout = layouts['_' + layout]
      return this.layout
    },
    loadLayout(layout) {
      if (!layout || !layouts['_' + layout]) {
        layout = 'default'
      }
      return Promise.resolve(layouts['_' + layout])
    }
  },
  components: {
    NuxtLoading
  }
}
