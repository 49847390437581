<template>
    <b-container fluid>
        <header-default></header-default>
        <div class="wrap" style="margin-top:60px;">
            <router-view />
        </div>
        <side-bar/>
        <footer-default></footer-default>
    </b-container>
</template>

<script>
import headerDefault from './components/header_default';
import footerDefault from './components/footer_default';
import sideBar from './components/sidebar';
import {isWechat,getParams,getToken} from '@/assets/js/fun'
import server from "@/assets/utils/request";
import API from '@/assets/utils/API';
export default {
    name: 'Layout',
    components:{headerDefault,footerDefault,sideBar},
    methods:{
        //在微信，先微信授权再去登录
        authorizationAndlogin(){
            if(isWechat()&&!getToken()){
                let params = getParams();
                if(!params.code){
                    let url = encodeURIComponent(window.location.href);
                    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx14481b6ea1c473f6&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
                }else{
                    server.send('POST',API.$WELOGIN,{
                        code:params.code
                    }).then(res=>{
                        this.$store.commit('SET_TOKEN',res.data.token);//设置token
                        this.$store.dispatch('GetUserInfo');//获取用户信息
                    })
                }
            }

        }
    },
    mounted(){
        this.authorizationAndlogin()  
    },
    watch: {
        '$store.getters.type'(newVal){  
            if(isWechat()){
                let url
                if(!this.$store.getters.phone){
                    url='/login/scanbind'
                }else{
                    url = newVal ? this.$route.fullPath :'/account/identity'
                } 
                this.$router.push(url)
            }

        },

    },
}
</script>

