import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import city from './modules/city'
import media from './modules/media'
import school from './modules/school'
import getters from './getters'

Vue.use(Vuex)

const store =()=> new Vuex.Store({
  modules: {
    user,
    city,
    media,
    school
  },
  getters
})

export default store
