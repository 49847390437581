<template>
    <b-container fluid>
        <header-default></header-default>
        <main class="login">
            <b-container>
                <div class="form_wrap" :style="isMobile?'width:auto':''">
                    <router-view />
                </div>
            </b-container>
        </main>
        <footer-default></footer-default>
    </b-container>
</template>

<script>
import headerDefault from './components/header_default';
import footerDefault from './components/footer_default';
import '@/assets/css/login.scss';
export default {
    name: 'Layout',
    components:{headerDefault,footerDefault},
    data(){
        return{
            isMobile:null
        }
    },
    mounted(){
        this.isMobile=/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
    }
}
</script>
<style lang="scss" scoped>
.login{
    background: rgba(0,0,0,.6) url('http://cdnsponsor.xiaoguokeji.com/login_bg02.jpg') center no-repeat;
    padding: 50px 0;
    margin-top: 60px;
    .form_wrap{
        width:382px;
        // height: 382px;
        padding-bottom: 52px;
        margin:68px auto 0;
        border-radius: 4px;
        overflow: hidden;
        background: rgba(255,255,255,0.8);
    }
    .container{
        min-height: calc(100vh - 238px);
    }
}
</style>


