import { getMedias,getAims } from '@/assets/js/fun'
const media = {
    state: {
        medias:[],
        aims:[]//推广目的 履行职能
    },
    mutations:{
        SET_MEDIAS:(state,medias)=>{
            state.medias = medias;
        },
        SET_AIMS:(state,aims)=>{
            state.aims = aims;
        },
    },
    actions:{
        //获取类别
        GetMedias({ commit }){
            getMedias().then(res=>{
                commit('SET_MEDIAS',res.data);
            }).catch(error => {
                console.log(error);
            })
        },
        //获取推广目的
        GetAims({ commit }){
            getAims().then(res=>{
                commit('SET_AIMS',res.data);
            }).catch(error => {
                console.log(error);
            })
        },
    }
};

export default media;