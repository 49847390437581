import { getToken, setToken, removeToken,getUserInfo } from '@/assets/js/fun'
import { resolve } from 'path';
import { reject } from 'q';
const user = {
    state: {
        admin:'',//1为管理员，0不是
        name:'',//用户名
        type:'',//用户类型 1:企业  2:学生
        phone:'',//注册手机号
        gender:'',
        access_token:getToken(),
        avatar:'',
        wechat:'',
        published:'',
        info:{},
        deposit:undefined,
        is_real: null
    },
    mutations:{
        SET_ADMIN:(state,admin)=>{
            state.admin = admin;
        },
        SET_NAME:(state,name)=>{
            state.name = name;
        },
        SET_TYPE:(state,type)=>{
            state.type = type;
        },
        SET_WECHAT:(state,wechat)=>{
            state.wechat = wechat;
        },
        SET_PHONE:(state,phone)=>{
            state.phone = phone;
        },
        SET_TOKEN:(state,token)=>{
            state.access_token = token;
            setToken(token);
        },
        SET_AVATAR:(state,avatar)=>{
            state.avatar = avatar;
        },
        SET_PUBLISHED:(state,published)=>{
            state.published = published;
        },
        SET_INFO:(state,info)=>{
            state.info = info;
        },
        SET_GENDER:(state,gender)=>{
            state.gender = gender;
        },
        SET_ISREAL:(state,is_real)=>{
            state.is_real = is_real;
        },
        SET_DEPOSIT:(state,deposit)=>{
            state.deposit = deposit;
        },
    },
    actions:{
        //获取用户数据
        GetUserInfo({ commit }){
            getUserInfo().then(res=>{
                let avatar = res.data.type
                commit('SET_TYPE',res.data.type);
                commit('SET_ADMIN',res.data.is_admin);
                commit('SET_PHONE',res.data.phone);
                commit('SET_WECHAT',res.data.wechat);
                commit('SET_AVATAR',res.data.avatar);
                commit('SET_PUBLISHED',res.data.published);
                commit('SET_INFO',res.data.info);
                commit('SET_GENDER',res.data.gender);
                commit('SET_DEPOSIT',res.data.deposit);
                commit('SET_NAME',res.data.name ? res.data.name :res.data.phone);
                commit('SET_ISREAL',res.data.is_real);
            }).catch(error => {
                // console.log(error);
            })
        },
        /**
         * 退出
         */
        RemoveToken({ commit }){
            removeToken();
            commit('SET_TYPE','');
            commit('SET_PHONE','');
            commit('SET_NAME','');
            commit('SET_ADMIN','');
        }
    }
};

export default user;