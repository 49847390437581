<template>
    <b-container fluid>
        <header-default></header-default>
        <main class="personal_center">
            <b-container>
                <div class="user_left">
                    <div class="user_info">
                            <div class="tip">{{type==1?"企业":"学生"}}</div>
                            <div class="avatar">
                                <i :class="['icon','iconfont',type==1?'iconqiye-copy':'iconxueshengcopy']"></i>
                            </div>
                            <p>{{name}}</p>
                        </div>
                        <div class="user_nav d-none d-lg-block">
                            <div class="nav_item" v-for="(item,index) in nav" :key="index" >
                                <p class="title">{{item.title}}</p>
                                <p class="item" v-for="childLink in item.child" 
                                    @click="toLink(childLink.link)" :style="{'color':childLink.link==$route.path?'#00ABFF':'#333'}">
                                    {{childLink.label}}
                                </p>
                            </div>
                        </div>
                </div>
                <div class="user_right">
                    <router-view />
                </div>
            </b-container>
        </main>
        <footer-default></footer-default>
    </b-container>
</template>

<script>
import headerDefault from './components/header_default';
import footerDefault from './components/footer_default';
import { mapGetters } from 'vuex';
export default {
    name: 'personalLayout',
    components:{headerDefault,footerDefault},
    computed:{
        ...mapGetters([
            'type',
            'name',
            'admin'
        ]),
        nav(){
            if(this.admin==1){
                return[
                    {
                        title:'信息管理',
                        child:[
                            {label:'活动信息',link:'/account/infoList'}
                        ]
                    },
                    {
                        title:'用户管理',
                        child:[
                            {label:'个人信息',link:'/account/info'}
                        ]
                    },
                    {
                        title:'Banner图管理',
                        child:[
                            {label:'首页banner',link:'/account/banner'}
                        ]
                    }
                ]
            }else{
                return[
                    {
                        title:'信息管理',
                        child:[
                            {label:'活动信息',link:'/account/infoList'}
                        ]
                    },
                    {
                        title:'用户管理',
                        child:[
                            {label:'个人信息',link:'/account/info'}
                        ]
                    }
                ]
            }
        }
    },
    data(){
        return{
            // nav:[
            //     {
            //         title:'信息管理',
            //         child:[
            //             {label:'活动信息',link:'/account/infoList'}
            //         ]
            //     },
            //     {
            //         title:'用户管理',
            //         child:[
            //             {label:'个人信息',link:'/account/info'}
            //         ]
            //     },
            //     {
            //         title:'Banner图管理',
            //         child:[
            //             {label:'首页banner',link:''}
            //         ]
            //     }
            // ]
        }
    },
    methods:{
        toLink(url){
            this.$router.push(url)
        }
    },
    mounted(){
        this.$store.dispatch('GetUserInfo');//获取信息
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/vars.scss';
.personal_center{
    padding-top: 60px;
    margin-top: 60px;
    background: #F5F5F5;
    .container{
        clear: both;
        content: "";
        overflow: hidden;
    }
    .user_left{
        width: 224px;
        height: 427px;
        margin-right: 10px;
        border-radius: 4px;
        background: #fff;
        float: left;
        .user_info{
            width: 100%;
            height: 160px;
            border-bottom: 1px solid #F5F5F5;
            padding-top: 25px;
            position: relative;
            overflow: hidden;
            .tip{
                width:182px;
                height: 36px;
                background: $theme-color;
                text-align: center;
                line-height: 36px;
                font-size: 18px;
                color: #fff;
                position: absolute;
                top: 12px;
                left: -60px;
                transform: rotate(-45deg)
            }
            .avatar{
                width: 80px;
                height: 80px;
                border-radius: 50%;
                background: red;
                overflow: hidden;
                margin: 0 auto 7px;
                text-align: center;
                line-height: 80px;
                i{
                    color: #fff;
                    font-size: 36px;
                }
            }
            p{
                width: 160px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                color: #333333;
                margin: 0 auto;
                text-align: center;
            }
        }
        .user_nav{
            padding: 0 30px;
            color: #333333;
            p{
                margin: 0;
            }
            .nav_item{
                margin-top: 25px;
                .title{
                    font-size: 16px;
                    font-weight: bold;
                }
                .item{
                    font-size: 14px;
                    padding-left: 20px;
                    cursor: pointer;
                    margin-top: 13px;
                }
            }
        }
    }
    .user_right{
        float: left;
        width: calc(100% - 234px);
    }
}
@media screen and (max-width: 750px) {
    .personal_center{
        padding-top: 20px;
        .user_left{
            float: none;
            width:100%;
            height: auto;
        }
        .user_right{
            float: none;
            width: 100%;
        }
    }
}
</style>