<template>
    <div class='sidebar d-none d-lg-block' >
        <div class="box">
            <i class="icon iconfont iconzixun"></i>
            <p class="sidebar_text">咨询服务</p>
        </div>
        <div class="qrBox">
            <img src="https://img.xiaoguokeji.com/51zanzhu_sidebar_qrcode_51.png" alt="" style="width:100%">
            <div class="triangle"></div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            showImg:false
        }
    },
    methods:{
    },
}
</script>
<style lang='scss'  scoped>
.sidebar{
    position: fixed;
    z-index: 9999;
    right: 0;
    top:45vh;
    .box{
        width: 46px;
        height: 160px;
        padding: 8px 0;
        cursor:pointer;
        text-align: center;
        background: #00ABFF;
        margin: 0 auto;
        box-shadow:4px 4px 12px 1px rgba(201,200,200,0.25);
        i{
            display: block;
            font-size: 24px;
            color: rgb(253, 253, 253);
        }
        .sidebar_text{
            margin: 8px 15px 0px 15px;
            font-size:16px;
            font-family:PingFangSC-Regular;
            font-weight:400;
            color:rgba(255,255,255,1);
        }
    }
    .qrBox{
        display: none;
        width:293px;
        height:490px;
        background:#fff;
        box-shadow:4px 4px 12px 1px rgba(201,200,200,0.25);
        position: absolute;
        top: -172px;
        left: -308px;
        &::after {
            content: '';
            position: absolute;
            width: 16px;
            height: 160px;
            top: 172px;
            left: 292px;
        }
        .triangle{
            width: 0;
            height: 0;
            border-left: 10px solid #FFF;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            position: absolute;
            top: 244px;
            left: 293px;

        }
    }
    &:hover {
        .qrBox {
            display: block;
        }
    }
}

</style>
