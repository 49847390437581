<template>
    <b-container fluid>
        <div class="activity">
            <router-view />
        </div>
        
    </b-container>
</template>

<script>

export default {
    name: 'Layout',
    components:{},
    head(){
        return{
            title:'登录'
        }
    },
    mounted(){
        
        let clientWidth = document.documentElement.clientWidth
        if(clientWidth<=1024){
            document.documentElement.style.fontSize=clientWidth/7.5+'px'
        }
    }
}
</script>

<style lang="scss" scoped>
.activity{
    height: 100vh;
    font-family:PingFangSC-Regular,PingFang SC;
    line-height: unset;
    font-size: .32rem;
}

input::-webkit-input-placeholder{
    color:#D8D8D8;
    font-size: .24rem;
}
input::-moz-placeholder{   /* Mozilla Firefox 19+ */
    color:#D8D8D8;
    font-size: .24rem;
}
input:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
    color:#D8D8D8;
    font-size: .24rem;
}
input:-ms-input-placeholder{  /* Internet Explorer 10-11 */ 
    color:#D8D8D8;
    font-size: .24rem;
}
</style>